<template>
  <div class="height-full">
    <v-card flat class="pa-6">
      <v-card-title class="f-s-20 px-0">Create Loan</v-card-title>
      <v-card-text class="pa-0">
        <wap-form ref="createCustomer"
                  v-model="layouts.data.input"
                  :layout-setting="layouts.settings.form">
        </wap-form>
      </v-card-text>
    </v-card>
    <div class="footer pa-6">
      <wap-btn
        theme="primary"
        :height="50"
        width="100%"
        @click="createNewLoan">Go</wap-btn>
    </div>
  </div>
</template>

<script>
import WapBtn from '@/components/base/WapBtn'
import WapForm from '@/components/base/WapForm'
import CREATE_CUSTOMER from '@/settings/layout-setting/system/create-customer'
import { OnlineApi } from '@/api'
import { Tool } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'Simulator',
  components: { WapForm, WapBtn },
  data: () => ({
    layouts: {
      settings: {
        form: CREATE_CUSTOMER.WAP_FORM
      },
      data: {
        showBtn: false,
        input: {
          email: '',
          firstName: '',
          lastName: '',
          employerName: '',
          streetAddress: '',
          optionalAddress: '',
          accountNo: '738896430',
          routingNo: '211274450',
          portfolioId: '',
          demoFlag: true
        }
      }
    }
  }),
  watch: {
    '$store.getters.getPortfolioJson.portfolioId': {
      handler (val) {
        this.layouts.data.input.portfolioId = val
      },
      deep: true
    }
  },
  mounted () {
    this.layouts.data.input.portfolioId = this.$store.getters.getPortfolioJson.portfolioId
  },
  methods: {
    createNewLoan () {
      this.$refs.createCustomer.$refs.observer.validate().then(result => {
        if (result) {
          OnlineApi.simulateData(this.layouts.data.input, (res) => {
            if (tool.isNotEmpty(res)) {
              const url = res.invitationUrl.split('#')
              this.$router.push(url[1])
            }
          }, (err) => {
            this.$store.commit('setPopupInformation', {
              message: err.message
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.v-card
  margin-bottom: 98px
.footer
  position: fixed
  bottom: 0
  width: 100%
  background: #ffffff
</style>
