<template>
  <ValidationObserver ref="observer" v-slot="{ validate, reset }">
    <div class="wap-form-layout">
      <template v-for="(item, index) in layoutSetting">
        <div
          :key="`v-flex-${index}`"
          :class="
               row ? 'lg12 p-l-10' : item.class === undefined ? 'lg12 p-l-10' : (item.class.indexOf('lg12') !==-1 ? item.class +' p-l-10  p-r-10' : item.class+' p-l-10 p-r-10' )
            "
          class=""
        >
          <ValidationProvider
            :key="`validation-provider-${index}`"
            v-slot="{ errors }"
            :name="item.label"
            :rules="item.rules"
          >
            <p class="m-t-20" v-if="item.controlType === 'title'">{{ item.label }} : {{ assist.currency.format(current[item.fieldKey]) }}</p>
            <wap-date-picker
              v-else-if="item.controlType === 'date'"
              v-model="current[item.fieldKey]"
              :necessarily="isNecessarily(item)"
              :error-message="errors[0]"
              :fieldKey="item.fieldKey"
              :label="item.label"
              :lessDate="item.lessDate"
              :moreDate="item.moreDate"
              :allowed="item.allowed"
              :class="className"
              :disabled="item.disabled"
            ></wap-date-picker>
            <wap-select
              v-else-if="item.controlType === 'select'"
              :label="item.label"
              :items="item.items"
              v-model="current[item.fieldKey]"
              :class="className"
              :necessarily="isNecessarily(item)"
              :error-message="errors[0]"
              :disabled="item.disabled"
            ></wap-select>
            <v-divider
              v-else-if="item.controlType === 'divider'"
              class="m-l-5 m-r-5"
            ></v-divider>
            <div
              :class="className"
              v-else-if="item.controlType === 'blank-divider'"
            >
            </div>
            <wap-text-field
              v-else
              v-model="current[item.fieldKey]"
              :necessarily="isNecessarily(item)"
              :disabled="item.disabled"
              :readonly="item.readonly"
              :error-message="errors[0]"
              :class="className"
              :format="item.format"
              :fieldKey="item.fieldKey"
              :label="item.label"
              :mask="item.mask"
              @blur="onBlurAction(item.fieldKey)"
            ></wap-text-field>
          </ValidationProvider>
        </div>
      </template>
    </div>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Tool, Currency } from '@/assets/js/util'
import WapTextField from '@/components/base/WapTextField'
import WapSelect from '@/components/base/WapSelect'
import WapDatePicker from '@/components/base/WapDatePicker'

const tool = new Tool()
const currency = new Currency()
export default {
  name: 'wap-form',
  components: {
    ValidationObserver,
    ValidationProvider,
    WapTextField,
    WapSelect,
    WapDatePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    row: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: ''
    },
    layoutSetting: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value (val) {
      this.current = val
    }
  },
  data () {
    return {
      current: this.value,
      assist: {
        tool: tool,
        currency: currency
      }
    }
  },
  methods: {
    isNecessarily (item) {
      const str = item.rules
      if (tool.isNotEmpty(str)) {
        return str.indexOf('required') !== -1
      }
      return false
    },
    onBlurAction (key) {
      this.$emit('blur', key)
    }
  }
}
</script>

<style lang="sass" scoped>
.wap-form-layout
  display: flex
  flex-wrap: wrap
  flex: 1 1 auto
  margin: -10px
  .lg4
    flex-basis: 33.3%
    flex-grow: 0
    max-width: 33.3%
  .lg6
    flex-basis: 50%
    flex-grow: 0
    max-width: 50%
  .lg12
    flex-basis: 100%
    flex-grow: 0
    max-width: 100%
  .Mlg6
    flex-basis: 50.1%
    flex-grow: 0
  .blank-lg12
    flex-basis: 100%
    flex-grow: 0
    max-width: 100%
    height: 30px
</style>
