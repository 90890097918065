import { DataType, Tool } from '@/assets/js/util'
import SYSTEM_ENUM from '@/settings/layout-setting/system/systemEnum'
const tool = new Tool()

const CREATE_CUSTOMER = {
  WAP_FORM: [
    { label: 'Email', fieldKey: 'email', rules: SYSTEM_ENUM.RULES.EMAIL, controlType: 'text', class: 'lg12' },
    {
      label: 'First Name',
      fieldKey: 'firstName',
      rules: SYSTEM_ENUM.RULES.FIRST_NAME,
      controlType: 'text',
      class: 'lg12'
    },
    {
      label: 'Last Name',
      fieldKey: 'lastName',
      rules: SYSTEM_ENUM.RULES.LAST_NAME,
      controlType: 'text',
      class: 'lg12'
    },
    { label: 'Employer Name', fieldKey: 'employerName', rules: SYSTEM_ENUM.RULES.EMPLOYER_NAME, controlType: 'text', class: 'lg12' },
    { label: 'Street Address', fieldKey: 'streetAddress', rules: SYSTEM_ENUM.RULES.STREET_ADDRESS, controlType: 'text', class: 'lg12' },
    { label: 'Apt, Suite, Building (Optional)', fieldKey: 'optionalAddress', rules: SYSTEM_ENUM.RULES.OPTIONAL_ADDRESS, controlType: 'text', class: 'lg12' },
    { label: 'Account No', fieldKey: 'accountNo', rules: SYSTEM_ENUM.RULES.BANK_ACCOUNT_NO, controlType: 'text', class: 'lg12' },
    { label: 'Routing No', fieldKey: 'routingNo', rules: SYSTEM_ENUM.RULES.BANK_ROUTING_NO, controlType: 'text', class: 'lg12' },
    { label: 'Mode', fieldKey: 'demoFlag', rules: SYSTEM_ENUM.RULES.REQUIRED, controlType: 'select', items: tool.getWapArrayByEnum(DataType.SIMULATOR_MODE), class: 'lg12' }
  ],
  TEMPLATE: {
    email: '',
    customerBankAccount: {
      bankName: 'BAXTER CREDIT UNION',
      bankPhone: '4864359247',
      bankAccountType: DataType.BankAccountTypeEnum.CHECKING_ACCOUNT.value,
      bankRoutingNo: '831675792',
      bankAccountNo: ''
    },
    customerEmployment: {
      employerName: '',
      employerEmail: 'employe@sztus.com',
      employerPhone: '8032835874',
      employerCity: 'Chengdu',
      employerState: DataType.StateEnum.CA.value,
      jobType: DataType.JobTypeEnum.FULL_TIME.value,
      jobTitle: 'Customer service',
      supervisorName: 'Wolf',
      supervisorPhone: '9513578624'
    },
    customerIdentity: {
      firstName: '',
      lastName: '',
      ssn: '',
      birthday: '1989-02-08',
      city: 'Chengdu',
      state: DataType.StateEnum.CA.value,
      zip: '29720',
      streetAddress: '',
      optionalAddress: ''
    },
    customerPayroll: {
      payrollType: DataType.PayrollTypeEnum.DIRECT_DEPOSIT.value,
      payrollFrequency: DataType.PayrollFrequencyEnum.WEEKLY.value,
      paydayOnHoliday: DataType.PaydayOnHolidayEnum.PREVIOUS_DAY.value,
      paydayOnAvailable: DataType.PaydayOnAvailableEnum.SAME_DAY.value,
      dayOfWeekPaid: DataType.DayOfWeekPaidEnum.MONDAY.value,
      incomeType: DataType.IncomeTypeEnum.EMPLOYED.value,
      lastPayday: '2022-06-20'
    },
    customerContact: [
      {
        type: DataType.ContactTypeEnum.EMAIL.value,
        value: ''
      }, {
        type: DataType.ContactTypeEnum.CELL_PHONE.value,
        value: ''
      },
      {
        type: DataType.ContactTypeEnum.HOME_PHONE.value,
        value: ''
      }
    ]
  }
}
export default CREATE_CUSTOMER
